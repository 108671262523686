import React from "react";
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import Login from './page/Login/Login';
import CardsExam from './page/CardsExam/CardsExam';
import Exam from './page/Exam/Exam';
import User from './page/User/User';

function Routers(){
    
    return(
        <Router basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route path="/cards" component={CardsExam} />
                <Route path="/exam/:id/:stage" component={Exam} />
                <Route path="/user" component={User} />
            </Switch>
        </Router>
    );
}

export default Routers;