import React, { Component } from 'react';
import './AudioPlayer.css';

class AudioPlayer extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            play: false,
            //vol: true,
            iconVol: '',
            time: '00:00',
            positionMove: 0
        };
    }
       
    componentDidMount() {
        this.audio.addEventListener("timeupdate", () => {
          let ratio = this.audio.currentTime / this.audio.duration;
          let position = (this.timeline.offsetWidth * ratio) + this.timeline.offsetLeft;
          
          this.positionHandle(position);
          let seconds = parseInt(this.audio.currentTime % 60);
          let minutes = parseInt((this.audio.currentTime / 60) % 60);
          seconds = (seconds < 10) ? '0'+ seconds : seconds;
          minutes = (minutes < 10) ? '0'+ minutes : minutes;
          this.setState({time: minutes + ':' + seconds});

          if(ratio === 1)
            this.setState({ play: false });

        });

        //this.volhandle.style.marginLeft = '130px';
       // this.setState({iconVol: 'volume_up'});
    };

    positionHandle = (position) => {
        let timelineWidth = this.timeline.offsetWidth - this.handle.offsetWidth;
        let handleLeft = position - this.timeline.offsetLeft ;
        if (handleLeft >= 0 && handleLeft <= timelineWidth) {
          this.handle.style.marginLeft = handleLeft + "px";
        }
        if (handleLeft < 0) {
          this.handle.style.marginLeft = "0px";
        }
        if (handleLeft > timelineWidth) {
          this.handle.style.marginLeft = timelineWidth + "px";
        }

      };

   /* positionVolHandle = (position) => {
        let vollineWidth = this.volline.offsetWidth - this.volhandle.offsetWidth;
        let volhandleLeft = position - (this.volline.offsetLeft + this.props.widthExam.offsetLeft);
        
        if (volhandleLeft >= 0 && volhandleLeft <= vollineWidth) {
            this.volhandle.style.marginLeft = volhandleLeft + "px";
          }
          if (volhandleLeft < 0) {
            this.volhandle.style.marginLeft = "0px";
          }
          if (volhandleLeft > vollineWidth) {
            this.volhandle.style.marginLeft = vollineWidth + "px";
          }
    }*/

    play = () => {
        if (this.state.play) {
            this.setState({ play: false });
            this.audio.pause();
        } else {
            this.setState({ play: true });
            this.audio.play();
        }
    }

    /*volume = () => {
        if (this.state.vol) {
            this.setState({ vol: false });
            this.audio.muted = true;            
        } else {
            this.setState({ vol: true });
            this.audio.muted = false;
        }
    }*/

    mouseMove = (e) => {
        let position = (e.pageX );
        this.audio.currentTime = ((e.pageX - (this.timeline.offsetLeft + this.props.widthExam.offsetLeft) ) / this.timeline.offsetWidth) * this.audio.duration;
        this.setState({positionMove: 1});
        this.positionHandle(position);
     
        
    };

    mouseUp = (e) => {
        window.removeEventListener('mousemove', this.mouseMove);
        window.removeEventListener('mouseup', this.mouseUp);
    };

    mouseDown = (e) => {
        window.addEventListener('mousemove', this.mouseMove);
        window.addEventListener('mouseup', this.mouseUp);
    };

   /* volumeMouseMove = (e) => {
        this.positionVolHandle(e.pageX);
        let volume = ((e.pageX - (this.volline.offsetLeft + this.props.widthExam.offsetLeft))  / this.volline.offsetWidth );

        if(volume < 0 ) {
            this.audio.volume = 0;
            this.setState({iconVol: 'volume_mute'});
            
        } else if(volume > 1 ) {
            this.audio.volume = 1;
            this.setState({iconVol: 'volume_up'});
        }else{
            this.audio.volume = volume;   
            if(volume >= 0.5){
                this.setState({iconVol: 'volume_up'});
            }
            if(volume < 0.5){
                this.setState({iconVol: 'volume_down'});
            }
            
        }
    };

    volMouseUp = (e) => {
        window.removeEventListener('mousemove', this.volumeMouseMove);
        window.removeEventListener('mouseup', this.volMouseUp);
    };
    
    volMouseDown = (e) => {
        window.addEventListener('mousemove', this.volumeMouseMove);
        window.addEventListener('mouseup', this.volMouseUp);
    };*/


    render() {
        
        return (
            <div className="AudioPlayer">
                <div className="content-audio" ref={(contentAudio) => {this.contentAudio = contentAudio}}>
                    <div className="audio-btn" onClick={this.play}><i className="material-icons">{!this.state.play ? "play_arrow" : "pause"}</i></div>
                    <div className="audio-range">
                        <div id="timeline" onClick={this.mouseMove} ref={(timeline) => { this.timeline = timeline }}>
                            <div id="handle" onMouseDown={this.mouseDown} ref={(handle) => { this.handle = handle }} />
                        </div>
                        <p className="audio-time">{this.state.time}</p>
                    </div>
                </div>

                <audio width="320" height="240" className="mejs__player" ref={(audio) => { this.audio = audio }}>
                    <source type="audio/mp3"  src={this.props.src}/>
                </audio>
            </div>
        ) 
    }
}

export default AudioPlayer;