import React, { Component } from 'react';
import './CardsExam.css';
import Header from '../../Components/Header/Header';
import {getExams} from '../../services/Api/Api';
import 'materialize-css/dist/js/materialize.js'
import {Col, Card, Row, Modal, Button} from 'react-materialize';
import { isArray } from 'util';
import {PATHAPP} from '../../constants/';


class CardsExam extends Component {
    constructor(props){
        super(props);

        this.state= {
            exams: {},
            haveAccess: true,
            message: ''
        }
        this.listExams = this.listExams.bind(this); 
        this.modalOpen = false;
        this.showModal = false;
    }

    componentDidMount() {
        this.listExams();
    }

    listExams = async () => {
        const exams = await getExams();

        if(exams.success == undefined){
            let list = exams.map( (item) => item);
            this.setState({exams: list});   
            return; 
        }


        if(exams.success == false){
            this.showModal = true;
            this.setState({message: exams.message});
            this.modalOpen = true;
            if(!exams.data.access){
                this.setState({haveAccess: false});
            }
        }
    }

    newTag = (dateCard) => {
        let date  = new Date(dateCard);
        date.setDate(date.getDate() + 6);
        let today = new Date();
        if(today.getTime() <= date.getTime() ){
            return true;
        }
        return false;
    }

    clickModal = () => {
        if(!this.haveAccess){
            sessionStorage.clear();
            window.location.href = PATHAPP;
        }
    }

    clickEnter = (exam) => {
        sessionStorage.setItem('title', exam.name);
        window.location.href = './exam/'+exam.id+'/1'; 
    }
    
  
    render() {
        let list = [];
        if(isArray(this.state.exams)){
             list = this.state.exams;
        }
        console.log(list)
        
        return ( 
           
            <div className="CardsExam"> 
                <Header title="Choose Test" user='true'/>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.100.2/css/materialize.min.css"/>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.100.2/js/materialize.min.js"></script> 
                {this.showModal ? <Modal header="Alert" open={!this.state.modalOpen} className="modal-alert" actions={<Button waves='green' modal='close' flat  onClick={this.clickModal} options={{onCloseEnd: this.clickModal}}>Close</Button> }>
                    <p>{this.state.message}</p>
                </Modal> : '' }
                <div className="list-cards" >
                    <Row>
                    {list.map((response) => (
                        <Col l={4} m={6} s={12} key={response.id}>
                            <Card key={response.id.toString} className='cards-exams' textClassName='white-text' title={response.name} actions={[<a onClick={() => {this.clickEnter(response)}} className="link-enter">Acessar</a>]}>
                                {(!this.newTag(response.date_created))? '' : <div key={response.id} className='tag-new' ></div>}
                            </Card>
                        </Col> 
                    ))}
                    </Row>
                </div>
            </div>
        );
    }
}

export default CardsExam;