import React, { Component } from 'react';
import logo from '../../img/logoIconeGray.svg';
import './Login.css';
import {auth} from '../../services/Api/Api';
import LoadingScreen from 'react-loading-screen';
import {PATHAPP} from '../../constants/';


class Login extends Component {
    constructor(props){
        super(props);
        this.state = {
            user: '',
            pass: '',
            error: false,
            errorMessage: '',
            loading: true
        }
    }

      
    changeUser = event => {
        this.setState({ user: event.target.value });
    }
    changePass = event => {
        this.setState({ pass: event.target.value });
    }
    login = async event => {
        event.preventDefault();
        let response = await auth(this.state.user, this.state.pass);
        if(!response.success){
            this.setState({
                error: true,
                errorMessage: response.message
            })
        }else{
            sessionStorage.setItem('token', response.data.token);
            window.location.href = `${PATHAPP}cards`;
        }
    }

    componentDidMount(){
        this.timerID = setInterval(
            () => this.load(),
            2000
        );
      
    }
    
    load(){
        this.setState({loading: false});
    }

    render() {
        
        return ( 
            (this.state.loading)? <LoadingScreen loading={true} bgColor='#f1f1f1' spinnerColor='#4F6AB1' logoSrc={logo}></LoadingScreen> :
        <div className="Login">
            <div className="content-login">
                <div className="block-login">
                    <form>
                        <img src={logo} className="logo-header"/>
                        <div className="fields">
                            <input type="text" className="field-user" placeholder="User" onChange={this.changeUser}/>
                            <input type="password" className="field-pass" placeholder="Password" onChange={this.changePass}/>
                            {(!this.state.error)? '': <small className='error'>{this.state.errorMessage}</small>}
                        </div> 
                        <button className="btn-enter" onClick={this.login}> ENTER </button> 
                    </form> 
                </div> 
                <div className="content-half"></div>
            </div>
        </div>
        );
    }
}

export default Login;