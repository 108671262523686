import React, { Component } from 'react';
import logo from '../../img/logoIcone.svg';
import './Header.css';
import {PATHAPP} from '../../constants/';

class Header extends Component {

    home = () => {
        window.location.href = `${PATHAPP}cards`;
    }

    user = () => {
        window.location.href = `${PATHAPP}user`;
    }

    logout = () => {
        console.log('etsadasdas');
        sessionStorage.clear();
        window.location.href = PATHAPP;
    }

    render() {
        return ( 
            <div className="Header"> 
                <div className="header-alfa">
                    <i className="material-icons home-header" onClick={this.props.user ? this.user : this.home}>{this.props.user ? 'people' :'home'}</i>
                    <img className="logo-alfa" alt="Alfabravo" src={logo}/><p class="logout" onClick={this.logout}>Sair</p>
                    <h3>{this.props.title}</h3>
                </div>
            </div>
            
        );
    }
}

export default Header;