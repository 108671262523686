import axios from 'axios';
import {BASEAPI} from '../../constants/';

const auth = (user, pass) => {
    const formData = new FormData();
    formData.append('user', user);
    formData.append('pass', pass);
    return axios({
        method: 'post',
        url: `${BASEAPI}/person/auth`,
        data: formData,
        dataType: 'json',
    })
    .then(response => {
        if(response.data.success){
            return response.data
        }else{
            return {
                'message': response.data.message,
                'error': true
            }
        }
    })
    .catch(resp => {
        console.log(resp);
    });
}

const getExams =  () => {
    const formData = new FormData();
    formData.append('token', sessionStorage.getItem('token'));
    
    return  axios({
        method: 'post',
        url: `${BASEAPI}get/exams`,
        data: formData,
        dataType: 'json',
    })
    .then(response => {
        if(response.data.success){
            return response.data.data;
        }
        return response.data;
    })
    .catch(resp => {
        console.log(resp);
    });
} 

const getQuestions =  (idExam, stage) => {
    const formData = new FormData();
    formData.append('id', idExam);
    formData.append('stage', stage);
    formData.append('token', sessionStorage.getItem('token'));
    
    return  axios({
        method: 'post',
        url: `${BASEAPI}get/questions`,
        data: formData,
        dataType: 'json',
    })
    .then(response => {
        return response.data;
    })
    .catch(resp => {
        console.log(resp);
    });
} 

const saveAnswer = (data) =>{
    let user = sessionStorage.getItem(user);
    
    const formData = new FormData();
    formData.append('idquestion', data['idQuestion']);
    formData.append('answer', data['answer']);
    formData.append('token', sessionStorage.getItem('token')); 
    
    return  axios({
        method: 'post',
        url: `${BASEAPI}set/answer`,
        data: formData,
        dataType: 'json',
    })
    .then(response => {
        return response.data;
    })
    .catch(resp => {
        console.log(resp);
    });
}

const getAnswers =  (idExam, stage) => {
    const formData = new FormData();
    formData.append('id', idExam);
    formData.append('token', sessionStorage.getItem('token'));
    
    return  axios({
        method: 'post',
        url: `${BASEAPI}get/answer/question`,
        data: formData,
        dataType: 'json',
    })
    .then(response => {
        if(response.data.success){
            return response.data.data;
        }
    })
    .catch(resp => {
        console.log(resp);
    });
} 

const getAddress = () =>{
    const formData = new FormData();
    formData.append('token', sessionStorage.getItem('token'));
    
    return  axios({
        method: 'post',
        url: `${BASEAPI}get/address`,
        data: formData,
        dataType: 'json',
    })
    .then(response => {
        return response.data;
    })
    .catch(resp => {
        console.log(resp);
    });
}

const setAddress = (address) => {
    const formData = new FormData();
    formData.append('token', sessionStorage.getItem('token'));
    address = JSON.stringify(address);
    formData.append('address', address);
    
    return  axios({
        method: 'post',
        url: `${BASEAPI}set/address`,
        data: formData,
        dataType: 'json',
    })
    .then(response => {
        return response.data;
    })
    .catch(resp => {
        console.log(resp);
    });
}

export {
    auth,
    getExams,
    getQuestions,
    saveAnswer,
    getAnswers,
    getAddress,
    setAddress
 };