import React, { Component } from 'react';
import './User.css';
import Header from '../../Components/Header/Header';
import {getAddress, setAddress} from '../../services/Api/Api';
import 'materialize-css/dist/js/materialize.js'
import {Col, Card, Row, Modal, Button} from 'react-materialize';
import { isArray } from 'util';
import ReactLoading from 'react-loading';
import {PATHAPP} from '../../constants/';


class User extends Component {
    constructor(props){
        super(props);

        this.state= {
            address: {},
            listAddress: {},
            haveAccess: true,
            message: '',
            billing_first_name: '',
            billing_last_name: '',
            billing_address_1: '',
            billing_city: '',
            billing_postcode:'',
            billing_country: '',
            billing_state: '',
            billing_phone: '',
            billing_email: '',
            menssageSuccess:'',
            menssageBool: false,
            success: true,
            spinner: false
        }
        this.modalOpen = false;
        this.showModal = false;
        this.getAddress = this.getAddress.bind(this);
        this.click = this.click.bind(this);
        this.updateData = this.updateData.bind(this);

    }

    componentDidMount() {
        this.setState({spinner: true});
        this.getAddress();
        
    } 

    newTag = (dateCard) => {
        let date  = new Date(dateCard);
        date.setDate(date.getDate() + 6);
        let today = new Date();
        if(today.getTime() <= date.getTime() ){
            return true;
        }
        return false;
    }

    clickModal = () => {
        if(!this.haveAccess){
            sessionStorage.clear();
            window.location.href = PATHAPP;
        }
    }
    
    getAddress = async () => {
        const dataUser = await getAddress();

        if(dataUser.success){
            let list = dataUser.data.map( (item) => item);
            this.setState({address: list}); 
            this.setState({
                billing_first_name: list[0]['billing_first_name'],
                billing_last_name: list[0]['billing_last_name'],
                billing_address_1: list[0]['billing_address_1'],
                billing_city: list[0]['billing_city'],
                billing_postcode: list[0]['billing_postcode'],
                billing_country: list[0]['billing_country'],
                billing_state: list[0]['billing_state'],
                billing_phone: list[0]['billing_phone'],
                billing_email: list[0]['billing_email']
            });
            this.setState({spinner: false});
            return; 
        }


        if(!dataUser.success){
            this.showModal = true;
            this.setState({message: dataUser.message});
            this.modalOpen = true;
            
        }
    }

    click = (event, label) => {
        this.setState({ [label]: event.target.value });
    }

    updateData = async() => {
        this.setState({spinner: true});

        let params = {
            billing_first_name: this.state.billing_first_name,
            billing_last_name: this.state.billing_last_name,
            billing_address_1: this.state.billing_address_1,
            billing_city: this.state.billing_city,
            billing_postcode: this.state.billing_postcode,
            billing_country: this.state.billing_country,
            billing_state: this.state.billing_state,
            billing_phone: this.state.billing_phone,
            billing_email: this.state.billing_email
        }

        let update = await setAddress(params);

        if(!update.success){
            this.setState({
                menssageSuccess: update.message, 
                menssageBool: true,
                success: false
            });
        }else{
            this.setState({
                menssageSuccess: update.message, 
                menssageBool: true,
                success: true
            });
        }
        this.setState({spinner: false});
    }

    
  
    render() {
        let list = [];
        let required = {
            'billing_first_name': 'Primeiro nome',
            'billing_last_name': 'Sobrenome',
            'billing_address_1': 'Endereço',
            'billing_city': 'Cidade',
            'billing_postcode': 'CEP',
            'billing_country': 'País',
            'billing_state': 'Estado',
            'billing_phone': 'Telefone',
            'billing_email': 'E-mail'
        }

        

        if(isArray(this.state.address)){
            list = this.state.address;
        }

        let disabled  = {
            billing_first_name: 'Primeiro nome',
            billing_last_name: 'Sobrenome',
        }  
        

        return ( 
           
            <div className="User"> 
                <Header title="Usuário"/>
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"/>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.100.2/css/materialize.min.css"/>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.100.2/js/materialize.min.js"></script> 
                {this.state.menssageBool ? <div className={(this.state.success) ? "messages messages-success" : "messages messages-error"}><p>{this.state.menssageSuccess}</p></div> : ""}
                {this.showModal ? <Modal header="Alert" open={!this.state.modalOpen} className="modal-alert" actions={<Button waves='green' modal='close' flat  onClick={this.clickModal} options={{onCloseEnd: this.clickModal}}>Close</Button> }>
                    <p>{this.state.message}</p>
                </Modal> : '' }
                {(this.state.spinner) ? <div className="loading"><ReactLoading type='spin' color="#334581"  width={'10%'} /> </div> : 
                    <div className="list-address" >
                        {list.map((response, index) => {   
                            return Object.keys(response).map((resp, ind) =>(
                                <Row key={index}>
                                    <label className="label-address ">{required[resp]}</label>
                                    <input type="text" value={this.state[resp]} onChange={(e) => this.click(e, resp)} disabled={disabled[resp] !== undefined}/>
                                </Row>
                            ))
                        })}
                        <div className="btn-action"><button className="btn-update" onClick={this.updateData}>Update</button></div>
                    </div>
                }
            </div>
        );
    }
}

export default User;