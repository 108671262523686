import React, { Component } from 'react';
import './Exam.css';
import Header from '../../Components/Header/Header';
import AudioPlayer from '../../Components/AudioPlayer/AudioPlayer';
import {getQuestions} from '../../services/Api/Api';
import 'materialize-css/dist/js/materialize.js'
import {Col,Row,Modal, Button} from 'react-materialize';
import { isArray } from 'util';
import ReactLoading from 'react-loading';
import {PATHAPP} from '../../constants/';



class Exam extends Component {
    constructor(props){
        super(props);
       this.state = {
            questions: {},
            showExpectedAnswer: 0,
            showSuggestedReadback: 0,
            showAudioScript: 0,
            showSuggestedInteraction: 0,
            answers: {},
            haveAccess: true,
            message: '',
            menssageSuccess:'',
            menssageBool: false,
            success: true,
            spinner: false
       };
       this.listQuestions = this.listQuestions.bind(this);
       this.html = this.html.bind(this);
       this.part = this.part.bind(this);
       this.answer = {};
       this.modalOpen = false;
    }

    clickModal = () => {
        if(!this.haveAccess){
            sessionStorage.clear();
            window.location.href = PATHAPP;
        }
    }
    
    showExpectedAnswer = (value) =>  {
        
        if(this.state.showExpectedAnswer){
            if(this.state.showExpectedAnswer === value)
                this.setState({showExpectedAnswer: 0});
            else
                this.setState({showExpectedAnswer: value});
        }else{
            this.setState({showExpectedAnswer: value});
        }
     
    }

    showSuggestedReadback = (value) =>  {
        if(this.state.showSuggestedReadback){
            if(this.state.showSuggestedReadback === value)
                this.setState({showSuggestedReadback: 0});
            else    
                this.setState({showSuggestedReadback: value});
            
        }else{
            this.setState({showSuggestedReadback: value});
        }
    }

    showSuggestedInteraction = (value) =>  {
        
        if(this.state.showSuggestedInteraction){
            if(this.state.showSuggestedInteraction === value)
                this.setState({showSuggestedInteraction: 0});
            else    
                this.setState({showSuggestedInteraction: value});
        }else{
            this.setState({showSuggestedInteraction: value});
        }
    }

    showAudioScript = (value) =>  {
        
        if(this.state.showAudioScript){
            if(this.state.showAudioScript === value)
                this.setState({showAudioScript: 0});
            else        
                this.setState({showAudioScript: value})
        }else{
            this.setState({showAudioScript: value});
        }
    }   
    

    listQuestions = async () => {
        let idexam = this.props.match.params.id;
        let stage = this.props.match.params.stage
        const questions = await getQuestions(idexam, stage);
        if(!questions.success){
            this.showModal = true;
            this.setState({message: questions.message});
            this.modalOpen = true;
            if(!questions.data.access){
                this.setState({haveAccess: false});
            }
            return false;
        }

        let list = questions.data.questions.map( (item) => item);
        if(questions.data.answers !== undefined){
            let listAnswers = questions.data.answers.map( (item) => item);
            this.setState({ answers: listAnswers});
           
        }
        this.setState({questions: list});
    }

    checkAnswer(idquestion){
        let answer = this.state.answers;
        let response = "";
        let haveID = false;
        if(Object.keys(answer).length == 0){
            response = "";
        }else{
            
            response = answer.map( (item, index) => {
                if(item.id_exam_question == idquestion){
                    haveID = true;
                    return (
                        <div className="answer-user-input group">
                            <p className="person"> Minimum interaction: </p>
                            <textarea className="answer-user" data-id={idquestion} onChange={this.part} value={this.answer[idquestion]}>{item.answer}</textarea>
                        </div>
                    )
                }
            });

            
        }
        if(!haveID){
            response = (
                <div className="answer-user-input group">
                    <p className="person"> Minimum interaction: </p>
                    <textarea className="answer-user" data-id={idquestion} onChange={this.part} value={this.answer[idquestion]}></textarea>
                </div>
            )
        }

        return response;
    }
  
  
    html = () => {
        let list = this.state.questions;
        let countSituation = 1;
        if(Object.keys(list).length === 0){
            return "";
        }
        return list.map( (item, index) => (
           
            <div className="exam-questions" key={item.id}> 
             {(this.state.title === '') ? this.setState({title: item.name}) : '' }
             {(item.question == '') ? '': (
                   <div className="exam group">
                        <p className="question" dangerouslySetInnerHTML={{__html: item.question}}></p>
                    </div>
                   
                )}
              {(item.situation == '') ? '': (
                   <div className="exam group">
                        <p className="person">Situation {countSituation++})</p>
                        <p className="question" dangerouslySetInnerHTML={{__html: item.situation}}></p>
                    </div>
                   
                )}
                 {(item.url_image == '') ? '' : (
                    <div className="group">
                       <img src={item.url_image}></img>
                    </div>
                )}
                {(item.url_audio == '') ? '' : (
                    <div className="group">
                       <AudioPlayer src={item.url_audio} widthExam={this.contentExam}/>
                    </div>
                )}
                {(item.text_audio == '') ? '' : (
                   <div>
                        <button className="btn-show-expected-answer" onClick={() => this.showAudioScript(item.id)}>{(this.state.showAudioScript == item.id) ? 'Hide script' : 'Show script'} </button>
                        {this.state.showAudioScript == item.id ? <div className="answer group" >
                            <p className="person">Audio Script:</p>
                            <p dangerouslySetInnerHTML={{__html: item.text_audio}}></p>
                        </div> : null}
                    </div>
                )}
               {(item.readback == '' || item.readback == null || item.readback == undefined) ? '' : (
                   <div>
                        <button className="btn-show-expected-answer" onClick={() => this.showSuggestedReadback(item.id)}>{(this.state.showSuggestedReadback == item.id) ? 'Hide suggested readback' : 'Show suggested readback'} </button>
                        {this.state.showSuggestedReadback == item.id ? <div className="answer group" >
                            <p className="person">Suggested readback:</p>
                            <p dangerouslySetInnerHTML={{__html: item.readback}}></p>
                        </div> : null}
                   </div>
               )}
               {(item.interaction == '') ? '' : (
                   <div>
                        <button className="btn-show-expected-answer" onClick={() => this.showSuggestedInteraction(item.id)}>{(this.state.showSuggestedInteraction == item.id) ? 'Hide suggested interaction' : 'Show suggested interaction'} </button>
                        {this.state.showSuggestedInteraction == item.id ? <div className="answer group" >
                            <p className="person">Suggested interaction:</p>
                            <p dangerouslySetInnerHTML={{__html: item.interaction}}></p>
                        </div> : null}
                    </div>
               )}
                {(item.answer == '') ? '' : (
                   <div>
                        <button className="btn-show-expected-answer" onClick={() => this.showExpectedAnswer(item.id)}>{(this.state.showExpectedAnswer == item.id) ? 'Hide suggested answer' : 'Show suggested answer'} </button>
                        {this.state.showExpectedAnswer == item.id ? <div className="answer group" >
                            <p className="person">Suggested answer:</p>
                            <p dangerouslySetInnerHTML={{__html: item.answer}}></p>
                        </div> : null}
                    </div>
                )}

              
                
                {(item.comment_examiner == '') ? '' : (
                    <div className="examiner group">
                        <p className="person">Examiner: </p>
                        <p>{item.comment_examiner}</p>
                    </div>
                )}                
            </div>
        ))
    }

    componentDidMount() {
        this.listQuestions();
      };

    
    next =  (save) => {
        this.setState({spinner: true});
        let idexam = this.props.match.params.id;
        let stage = this.props.match.params.stage;
        let count = Object.keys(this.answer).length;
        let bool = true;
        
        // if(count > 0){
        //     Object.keys(this.answer).map( async (item, index) => {
        //        let data = {
        //            'idQuestion': item,
        //            'answer': this.answer[item]
        //        } 

        //        let response = await saveAnswer(data); 
        //        if(!response.success){
        //             bool = false;
        //             this.setState({
        //                 menssageSuccess: response.message, 
        //                 menssageBool: true,
        //                 success: false
        //             });
        //        }else{
        //            this.setState({
        //                menssageSuccess: response.message, 
        //                menssageBool: true,
        //                success: true
        //             }); 
        //        }
        //     });
        // }
        
        if(bool && !save){
            if( stage < 4 ){
                this.setState({spinner: false});
                this.props.match.params.stage = parseInt(stage)+1;
                window.location.href = `${PATHAPP}exam/${idexam}/${this.props.match.params.stage}`;
            }
        }
        this.setState({spinner: false});
    }

    prev = (save) => {
        this.setState({spinner: true});
        let idexam = this.props.match.params.id;
        let stage = this.props.match.params.stage;
        let count = Object.keys(this.answer).length;
        let bool = true;
        
        if(bool && !save){
            if( stage > 1 ){
                this.setState({spinner: false});
                this.props.match.params.stage = parseInt(stage)-1;
                window.location.href = `${PATHAPP}exam/${idexam}/${this.props.match.params.stage}`;
            }
        }
        this.setState({spinner: false});
    }

    part = (event) => { 
        const target = event.target;
        var id = event.target.attributes.getNamedItem('data-id').value;
        this.answer[id] = target.value;
    }
      
      
    render() {
        let list = [];
        let elements = '';
        let idexam = this.props.match.params.id;
        let stage = this.props.match.params.stage

        if(isArray(this.state.questions) && this.state.questions.length > 0){
             list = this.state.questions;
             elements = this.html();
        }
              
        return(
            <div className="Exam" >
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.100.2/css/materialize.min.css"></link>
                <Header title={sessionStorage.getItem('title')}/>
                <div class="subtitle">  
                    <h4>{
                        (this.props.match.params.stage === '1') ? 'Parte I - Aviation topics' :
                        (this.props.match.params.stage === '2') ? 'Parte II - Interacting as a Pilot' :
                        (this.props.match.params.stage === '3') ? 'Parte III - Unexpected Situations' :
                        (this.props.match.params.stage === '4') ? 'Parte IV - Picture Description and Discussion' : ''}</h4>
                </div>
                {this.state.menssageBool ? <div className={(this.state.success) ? "messages messages-success" : "messages messages-error"}><p>{this.state.menssageSuccess}</p></div> : ""}
                {this.modalOpen ? <Modal header="Alert" open={!this.state.modalOpen} className="modal-alert" actions={<Button waves='green' modal='close' flat  onClick={this.clickModal} options={{onCloseEnd: this.clickModal}}>Close</Button> }>
                    <p>{this.state.message}</p>
                </Modal> : '' }

               
                <div className="actions"> 
                    {/* <div className="eraser" onClick={() => this.next(true)}><img src={eraser}/></div> */}
                    {/* <div className="save" onClick={() => this.next(true)}><i className="material-icons">save</i></div> */}
                </div>
                <div className="prev-part"  onClick={() => this.prev(false)}>
                        <p>Previous</p>
                    {/* <i className="material-icons"   onClick={() => this.prev(false)}>play_arrow</i> */}
                </div>

                {(this.state.spinner) ? <div className="loading"><ReactLoading type='spin' color="#334581"  width={'10%'} /> </div> : 
                    <div className="content-exam" ref={(contentExam) => {this.contentExam = contentExam}}>
                        { elements }
                    </div>
                }
                <div className="next-part" onClick={() => this.next(false)}>
                    <p>Next</p>
                    {/* <i className="material-icons"   onClick={() => this.next(false)}>play_arrow</i> */}
                </div>

                <footer>
                    <div className="parts">
                        <Row>
                            <Col m={3} s={3} className={(this.props.match.params.stage === '1') ? 
                            "active" : (this.props.match.params.stage > 1) ? "passed": "desactive"}>
                                <div className="title-part" onClick={this.part}>
                                    <a href={`${PATHAPP}exam/${idexam}/1`}onClick={this.part}>PART I</a>
                                </div>
                            </Col>
                            <Col m={3} s={3} className={(this.props.match.params.stage === '2') ? 
                            "active" : (this.props.match.params.stage > 2) ? "passed": "desactive"}>
                                <div className="title-part" onClick={this.part}>
                                <a href={`${PATHAPP}exam/${idexam}/2`}   >PART II</a>
                                </div>
                            </Col>
                            <Col m={3} s={3} className={(this.props.match.params.stage === '3') ? 
                            "active" : (this.props.match.params.stage > 3) ? "passed": "desactive"}>
                                <div className="title-part" onClick={this.part}>
                                <a href={`${PATHAPP}exam/${idexam}/3`} onClick={this.part}>PART III</a>
                                </div>
                            </Col>
                            <Col m={3} s={3} className={(this.props.match.params.stage === '4') ? 
                            "active" : (this.props.match.params.stage > 4) ? "passed": "desactive"}>
                                <div className="title-part" onClick={this.part}>
                                    <a href={`${PATHAPP}exam/${idexam}/4`} onClick={this.part}>PART IV</a>
                                </div>
                            </Col>
                        </Row>    
                    </div>
                </footer>
            </div>

            
            
        )
    }
}

export default Exam;